import axios from 'axios';

export const FETCH_CLINICS = 'FETCH_CLINICS';
export const SELECT_CLINIC = 'SELECT_CLINIC';
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';
export const LOCATE_USER = 'LOCATE_USER';

const ax = axios.create({
    baseURL: 'http://klinikfinder.dev.zseer.eu/api/public'
})

export function updateViewport(data) {
    return { type: UPDATE_VIEWPORT, payload: data }
}

export function selectClinic(data) {
    return { type: SELECT_CLINIC, payload: data }
}

export function fetchClinics(data) {
    
    const request = ax.post('/clinics', data);
    return {
        type: FETCH_CLINICS,
        meta: {
            latitude: data.lat,
            longitude: data.long
        },
        payload: request
    }
  };

export const locate_user = () => {
    const getPosition = function (options) {
        return new Promise(function (resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    return(dispatch) => {
        getPosition()
            .then((position) => {
                dispatch({ 
                    type: LOCATE_USER,
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude
                })
            })
            .catch((err) => {
                fetchClinics({
                    latlong: [49.872826, 8.651193],
                    radius: 20,
                    center: true
                })
                console.error(err.message)
            });
    }
};