import { FETCH_CLINICS, SELECT_CLINIC, UPDATE_VIEWPORT, LOCATE_USER } from './actions';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';
const initialState = {
    clinics: [],
    selected: [],
    userLatLong: [],
    viewport: {
        latitude: 49.872826,
        longitude: 8.651193,
        zoom: 12,
        width: "100%",
        height: "100%",
        pitch: 0,
        transitionDuration: 1500,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: easeCubic
    },
    popupInfo: null
};

export default (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_CLINICS:   
            // if (action.latlong.length > 0) {
                return {
                    ...state,
                    clinics: (typeof action.payload.data !== 'undefined') ? action.payload.data : [],
                    viewport: {
                        ...state.viewport,
                        latitude: parseFloat((action.meta.latitude).toFixed(6)),
                        longitude: parseFloat((action.meta.longitude).toFixed(6)),
                    }
                }
           // } 
           /* else {
                return {
                    ...state,
                    clinics: (typeof action.payload.data !== 'undefined') ? action.payload.data.clinics : []
                };
            } */

        case SELECT_CLINIC:
            return {
                ...state,
                selected: action.payload,
                viewport: {
                    ...state.viewport,
                    latitude: (action.payload.length > 1) ? parseFloat(state.clinics[action.payload[0]].latitude) : state.viewport.latitude,
                    longitude: (action.payload.length > 1) ? parseFloat(state.clinics[action.payload[0]].longitude) : state.viewport.longitude
                },
                popupInfo: state.clinics[action.payload[0]] || null
            };

        case UPDATE_VIEWPORT:
            return {
                ...state,
                viewport: action.payload
            }

        case LOCATE_USER:
            /* fetchClinics({
                latlong: [action.latitude, action.longitude],
                radius: 20,
                center: true
            }); */
            return {
                ...state,
                viewport: {
                    ...state.viewport,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    zoom: 14
                },
                userLatLong: [action.latitude, action.longitude]
            }

        default:
            return state
    }
}