import React, { Component } from "react";
import MapGL, { Marker, Popup, NavigationControl } from 'react-map-gl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectClinic, updateViewport } from "../modules/actions";
import ClinicPin from '../components/pin';
import ClinicInfo from '../components/info';



const APIKEY = 'pk.eyJ1Ijoiem9sdGFuc2VlciIsImEiOiJjanNjYjd4cWQwajkzNGJvYTd4Z3BuZGFtIn0.cDN0Zn4S1tbx8qWkFCxqBQ';
const navStyle = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '10px'
};

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {     
        }
    }
    _renderClinicMarker = (clinic, index) => {
        let markerClass = (this.props.clinics.selected[1] === clinic.id) ? 'is-active' : '';
        return (
            <Marker
                className={markerClass}
                key={`marker-${clinic.id}`}
                longitude={parseFloat(clinic.longitude)}
                latitude={parseFloat(clinic.latitude)} >
                <ClinicPin onClick={() => this.props.selectClinic([index, clinic.id])} />
            </Marker>
        );
    }

    _renderPopup() {
        const { popupInfo } = this.props.clinics;
    
        return popupInfo && (
            <Popup tipSize={10}
                offsetLeft={15} offsetTop={-5}
                dynamicPosition={false}
                longitude={parseFloat(popupInfo.longitude)}
                latitude={parseFloat(popupInfo.latitude)}
                closeOnClick={false}
                onClose={() => this.props.selectClinic([])} >
                <ClinicInfo info={popupInfo} />
            </Popup>
        );
    }


    render() {
        return (
            <MapGL
                width='100%'
                height='100%'
                mapboxApiAccessToken={APIKEY}
                offsetCenter={{x: 200, y: 0}}
                {...this.props.clinics.viewport}
                onViewportChange={this.props.updateViewport}
            >
                {this.props.clinics.clinics.map(this._renderClinicMarker)}
                {this._renderPopup()}

                <div className="nav" style={navStyle}>
                    <NavigationControl onViewportChange={this.props.updateViewport} />
                </div>

            </MapGL>
        );
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { selectClinic, updateViewport },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Map)
