import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchClinics } from "../modules/actions";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

const radius = [5, 10, 20, 50, 100];

class ClinicFilter extends Component {
    constructor(props) {
        super(props);
        let parent = this;

        // this.props.locate_user();
        this.state = {
            radiusIndex: 3,
            radiusClass: '',
            query: {
                lat: this.props.clinics.viewport.latitude,
                long: this.props.clinics.viewport.longitude,
                radius: 50,
                center: false
            }
        }

        this.geocoder = new MapboxGeocoder({
            accessToken: 'pk.eyJ1Ijoiem9sdGFuc2VlciIsImEiOiJjanNjYjd4cWQwajkzNGJvYTd4Z3BuZGFtIn0.cDN0Zn4S1tbx8qWkFCxqBQ',
            placeholder: 'Nach Adresse, Stadt oder PLZ suchen',
            flyTo: false,
            countries: 'de',
        });

        this.geocoder.on('result', function(ev) {
            parent.setState({
                ...parent.state,
                query: {
                    ...parent.state.query,
                    lat: ev.result.geometry.coordinates[1],
                    long: ev.result.geometry.coordinates[0],
                }
            })
        });

        
        this.handleCenterChange = this.handleCenterChange.bind(this);
        this.handleChangeRadius = this.handleChangeRadius.bind(this);
        this.handleSearch = this.handleSearch.bind(this);


    }

    componentDidMount() {
        this.props.fetchClinics(this.state.query);
        document.getElementById('geocoder_input').appendChild(this.geocoder.onAdd());
    }


    handleCenterChange(event) {
        this.setState({ 
            query: {
                ...this.state.query,
                center: !this.state.query.center 
            }
        });
    }

    handleSearch() {
        this.props.fetchClinics(this.state.query);
    }

    handleChangeRadius(direction) {
        let currentIndex = this.state.radiusIndex;
        if (direction === 'up') {
            if (currentIndex < radius.length) {
                let radClass = (currentIndex + 2 === radius.length) ? 'disable-up' : '';
                this.setState({ 
                    ...this.state,
                    radiusIndex: currentIndex + 1, 
                    radiusClass: radClass,
                    query: {
                        ...this.state.query,
                        radius: radius[currentIndex + 1]
                    }
                });
            }
        }
        if (direction === 'down') {
            if (currentIndex > 0) {
                let radClass = (currentIndex - 1 === 0) ? 'disable-down' : '';
                this.setState({ 
                    ...this.state,
                    radiusIndex: currentIndex - 1, 
                    radiusClass: radClass,
                    query: {
                        ...this.state.query,
                        radius: radius[currentIndex - 1]
                    }
                 });
            }
        }
    }

    render() {
        return (
            <div className="filter-wrap">
                <h2>Finden Sie Kliniken, die das MyCalimera-Programm kennen, in Ihrer Nähe:</h2>
                <div id="geocoder_input"></div>
                {/* <input type="text" value={this.state.searchfield} onChange={this.handleInputChange} placeholder="Nach Adresse, Stadt oder PLZ suchen" /> */}
                <div className="filter-bottom-container">
                    <div className={`radius-select ${this.state.radiusClass}`}>
                        <button className="radius-down" onClick={() => this.handleChangeRadius('down')}></button>
                        <button className="radius-up" onClick={() => this.handleChangeRadius('up')}></button>
                        <div className="radius-value">{radius[this.state.radiusIndex]}</div>
                        <span>Kilometer im Umkreis</span>
                    </div>
                    <div className="center-select">
                        <input type="checkbox" checked={this.state.query.center} onChange={this.handleCenterChange} id="centerChange" />
                        <label htmlFor="centerChange"><i className="icon"></i> mit My Calimera</label>
                    </div>
                </div>
                <button className="search" onClick={this.handleSearch}>Suchen starten</button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchClinics },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClinicFilter)
