import React, {PureComponent} from 'react';
export default class ClinicPin extends PureComponent {

  render() {
    const {onClick} = this.props;

    return (
        <div className="map-pin" onClick={onClick}></div>
    );
  }
}