import 'babel-polyfill';
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
//import { ConnectedRouter } from 'connected-react-router'
import store from './store'
import App from './containers/app'

import 'sanitize.css/sanitize.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
      <div id="wrap">
          <App />
      </div>
  </Provider>,
  target
)
