import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Map from "../Map";
import ClinicList from '../ClinicList';

class App extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
    }

    render() {
        return (
            <div className="App">
                <div id="map-container">
                    <Map />
                </div>
                <div className="clinic-list">
                    <ClinicList />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {  },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
