import React, { PureComponent } from 'react';

export default class ClinicInfo extends PureComponent {

    render() {
        const { info } = this.props;

        return (
            <div className="popup-content-wrapper">
                <h2 className="popup--name">{info.category} {info.zentrum === '1' ? <span>&ndash; mit MyCalimera</span> : <span></span>} </h2>
                <div className="popup-content">
                    <p><strong>{info.name}</strong></p>
                    <p>{info.address} <br /> {info.zip} {info.city}</p>
                    <p>
                        <a href={`mailto:${info.email}`}>{info.email}</a> <br />
                        <a href={info.url} target="_blank">{info.url}</a>
                    </p>
                    <p>{info.phone}</p>
                </div>
                <div className="distance">{info.distance} km</div>
            </div>
        );
    }
}