import React, { PureComponent } from 'react';

export default class ClinicListItem extends PureComponent {

    render() {
        const { clinic, selected } = this.props;
        let clinicClass = (selected) ? 'clinic-list--item selected' : 'clinic-list--item';
        return (
            <div className={clinicClass} onClick={this.props.onClick}>
                <h2 className="clinic-list--item--name">{clinic.category} {clinic.zentrum === '1' ? <span>&ndash; mit MyCalimera</span> : <span></span>} </h2>
                <div className="clinic-list--item-content">
                    <p><strong>{clinic.name}</strong></p>
                    <p>{clinic.address} <br /> {clinic.zip} {clinic.city}</p>
                    <p>
                        <a href={`mailto:${clinic.email}`}>{clinic.email}</a> <br />
                        <a href={clinic.url} target="_blank">{clinic.url}</a>
                    </p>
                    <p>{clinic.phone}</p>
                </div>
                <div className="distance">{clinic.distance} km</div>
            </div>
        );
    }
}