import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClinicFilter from './ClinicFilter';
import ClinicListItem from '../components/ClinicListItem';
import { selectClinic } from '../modules/actions';
import { Scrollbars } from 'react-custom-scrollbars';

class ClinicList extends Component {
    constructor(props) {
        super(props);
    }

    _renderListItems(clinic, index) {
        return (
            <ClinicListItem key={index} clinic={clinic} selected={(this.props.clinics.selected[1] === clinic.id)} onClick={() => this.props.selectClinic([index, clinic.id])}/>    
        )
    }

    render() {
        return (
            <div className="list-wrap">
                <ClinicFilter />
                <span className="result">{this.props.clinics.clinics.length} Suchergebnisse: </span>
                <div className="list-container">
                    <Scrollbars>
                        {this.props.clinics.clinics.map(this._renderListItems, this)}
                    </Scrollbars>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { selectClinic },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClinicList)
